<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="项目名称：">
        <el-input
          v-model.trim="formInline.interviewProjectName"
          v-check-permission
          placeholder="请输入项目名称"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="clearData()"> 重置 </el-button>
        <el-button
          v-if="$checkPermission('ADDPROJECT', $route)"
          type="primary"
          @click="dataOperation(null, 1)"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="id" label="项目编码" align="center" />
      <el-table-column prop="name" label="项目名称" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="240"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('EDITPROJECT', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$checkPermission('DELPROJECT', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 2)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="titleType + '随访项目'"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <h3
        v-if="titleType === '删除'"
        style="margin-bottom: 20px; text-align: center"
      >
        确定删除<span style="color: red; font-weight: blod">{{
          form1.name
        }}</span
        >项目？
      </h3>
      <el-form
        v-if="dialogVisible && titleType !== '删除'"
        ref="form1"
        label-position="right"
        :model="form1"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <el-form-item label="项目编码:" prop="id">
          <el-input
            v-model="form1.id"
            v-check-permission
            placeholder="请输入项目编码"
            style="width: 80%"
            :disabled="titleType === '修改'"
            maxlength="30"
            @keyup.enter.native="saveProject()"
          />
        </el-form-item>
        <el-form-item label="项目名称:" prop="name">
          <el-input
            v-model="form1.name"
            v-check-permission
            placeholder="项目名称"
            style="width: 80%"
            maxlength="50"
            @keyup.enter.native="saveProject()"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveProject()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { positiveInteger } from '@/utils/verificationRule.js';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      titleType: '',
      loading: false, // 给列表加上loading效果
      dialogVisible: false, // 控制新增、编辑弹框显示与否
      formInline: {
        interviewProjectName: '',
      },
      form1: {
        name: '',
        id: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      rules: {
        id: [
          { required: true, message: '请输入项目编码', trigger: 'blur' },
          { required: true, validator: positiveInteger, trigger: 'blur' },
        ],
        name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {},
  mounted() {
    this.getFollowUpProjectList();
  },
  methods: {
    ...mapActions('basicDictionary', ['organPageQuery', 'organEdit']),
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getFollowUpProjectList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getFollowUpProjectList();
    },
    // 重置
    clearData() {
      this.formInline = {
        // 查询条件
        interviewProjectName: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getFollowUpProjectList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getFollowUpProjectList();
    },
    // 分页查询用户套餐列表
    getFollowUpProjectList() {
      this.loading = true;
      const params = {
        ...this.formInline,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api
        .interviewProjectPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      switch (type) {
        case 0:
          this.form1 = {
            name: item.name,
            id: item.id,
          };
          this.titleType = '修改';
          break;
        case 1:
          this.form1 = {
            interviewProjectName: '',
            id: '',
          };
          this.titleType = '新增';
          break;
        default:
          this.titleType = '删除';
          this.form1 = {
            name: item.name,
            id: item.id,
          };
          break;
      }
      this.dialogVisible = true;
    },
    // 新增项目
    saveProject() {
      const { titleType, addProject, updateProject, delProject, form1 } = this;
      if (titleType !== '删除') {
        this.$refs['form1'].validate((valid) => {
          if (valid) {
            const param = {
              ...form1,
            };
            console.log(param);
            if (titleType === '修改') {
              updateProject(param);
            } else {
              addProject(param);
            }
          } else {
            return false;
          }
        });
      } else {
        delProject(form1.id);
      }
    },
    // 新增接口
    addProject(params) {
      this.$api.interviewProjectCreate(params).then((res) => {
        if (res.code === 0) {
          this.dialogVisible = false;
          this.$message.success('新增随访项目成功');
          this.getFollowUpProjectList();
          this.$refs['form1'].resetFields();
        }
      });
    },
    // 更新接口
    updateProject(params) {
      this.$api.interviewProjectUpdate(params).then((res) => {
        if (res.code === 0) {
          this.dialogVisible = false;
          this.$message.success('更新随访项目成功');
          this.getFollowUpProjectList();
          this.$refs['form1'].resetFields();
        }
      });
    },
    // 删除接口
    delProject(id) {
      this.$api.interviewProjectDel({ id: id }).then((res) => {
        if (res.code === 0) {
          this.dialogVisible = false;
          this.$message.success('删除随访项目成功');
          this.getFollowUpProjectList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
